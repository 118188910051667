// Проверка того, что сервер вернул страницу входа. "Грязный хак", зато просто.
function checkSessionEnd(p) {
    if (p && typeof p == "string") {
        if (p.match(/<!DOCTYPE[\s\S]*login-form/g)) {
            $("body").html("");
            alert("Ваша сессия истекла, вы будете перенаправлены на страницу входа.");
            window.location = appBase + "Home";
        }
    }
}

// Разрешает/запрещает действия на странице
var scrHiderDisableCount = 0;
function disablePage(d) {
    var e = $("#fsHider");
    if (d) {
        e.removeClass();
        e.addClass("disable");
        scrHiderDisableCount++;
    } else {
        // Обязательно считать вызовы, бывает отключение из отключения
        if (--scrHiderDisableCount == 0) {
            e.removeClass();
            e.addClass("none");
        }
    }
}

export {
    checkSessionEnd,
    disablePage
};