import { checkSessionEnd, disablePage } from './utils.js';

// Обёртка над POST
function ajaxPost(url, data, success, dataType, keepEnabled) {
    keepEnabled = !!keepEnabled;
    if (!keepEnabled)
        disablePage(true);
    // Добавление CSRF токена
    if (typeof csrfToken != "undefined") {
        if (data) {
            if (typeof data == "string") {
                if (data)
                    data += "&csrfToken=" + csrfToken;
            } else {
                data.csrfToken = csrfToken;
            }
        }
    }
    addAjaxHandlers($.post(url, data, success, dataType), keepEnabled);
}

/**
 * Обёртка над GET
 * @deprecated
 */
function ajaxGet(url, data, success, dataType, keepEnabled, useCache) {
    keepEnabled = !!keepEnabled;
    if (!keepEnabled)
        disablePage(true);
    if (data && typeof data == 'object' && !useCache) data["_"] = $.now(); // Отключение кэша
    addAjaxHandlers($.get(url, data,success, dataType), keepEnabled);
}

// Добавляет стандартные обработчики для Ajax
var gXhrError = null;
function addAjaxHandlers(q, keepEnabled) {
    q.always(function (data) {
        if(!keepEnabled)
            disablePage(false);
        checkSessionEnd(data);
        if (data && data.responseText)
            checkSessionEnd(data.responseText);
    }).fail(function (data) {
        gXhrError = data;
        if (data.status == 401)
            location.href = appBase;
        else if(data.status&&data.statusText&&data.status!=200)
            alert("Произошла внутренняя ошибка " + data.status.toString() + " " + data.statusText.toString());
    });
}

export {
    ajaxGet,
    ajaxPost,
}